import React, { useState, useEffect } from 'react';
import './SummaryReportForAdmin.scss';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { getFilterDropDownData, getSummaryReport, getSummaryReportCsv } from '../../../store/modules/product/actions';

const HnlIndentingSummaryReport = () => {
  const dispatch = useDispatch();
  const [regionName, setRegionName] = useState([]);
  const [plantName, setPlantName] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [productName, setProductName] = useState([]);
  const [regionNames, setRegionNames] = useState(null);
  const [plantNames, setPlantNames] = useState(null);
  const [productCategories, setProductCategories] = useState(null);
  const [productNames, setProductNames] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getFilterDropDownData());
        if (response) {
          const {
            regionNames: fetchedRegionNames,
            plantNames: fetchedPlantNames,
            productCategories: fetchedProductCategories,
            productNames: fetchedProductNames,
          } = response;

          setRegionNames(fetchedRegionNames);
          setPlantNames(fetchedPlantNames);
          setProductCategories(fetchedProductCategories);
          setProductNames(fetchedProductNames);
        }
      } catch (error) {
        console.error('Error fetching filter dropdown data:', error);
      }
    };
    fetchData();
  }, [dispatch]);

  const getReports = async (values = {}) => {
    try {
      const response = await dispatch(getSummaryReport(values));
      if (response) {
        setData(response);
      }
    } catch (error) {
      console.error('Error fetching Summary Report:', error);
    }
  };
  useEffect(() => {
    getReports();
  }, []);

  const clearFilters = () => {
    setRegionName(null);
    setPlantName(null);
    setProductCategory(null);
    setProductName(null);
    setStartDate(null);
    setEndDate(null);
    getReports();
  };

  const handleOnFilter = async () => {
    const { value: formValues } = await Swal.fire({
      title: 'Please select filters',
      html: `
        <div class="dropdown-container">
          <div class="dropdown-wrapper">
            <select id="swal-input1" class="dropdown">
              <option value="">Select Region Name</option>
              ${regionNames.map((item) => `<option value="${item}" ${item === regionName ? 'selected' : ''}>${item}</option>`).join('')}
            </select>
          </div>
           <div class="dropdown-wrapper">
            <select id="swal-input3" class="dropdown">
              <option value="">Select Product Category</option>
              ${productCategories.map((item) => `<option value="${item}" ${item === productCategory ? 'selected' : ''}>${item}</option>`).join('')}
            </select>
          </div>
        </div>
        <div class="dropdown-container">
          <div class="dropdown-wrapper product-name-dropdown">
            <select id="swal-input4" class="dropdown">
              <option value="">Select Product Name</option>
              ${productNames.map((item) => `<option value="${item}" ${item === productName ? 'selected' : ''}>${item}</option>`).join('')}
            </select>
          </div>
          <div class="dropdown-wrapper">
            <select id="swal-input2" class="dropdown">
              <option value="">Select Plant Name</option>
              ${plantNames.map((item) => `<option value="${item}" ${item === plantName ? 'selected' : ''}>${item}</option>`).join('')}
            </select>
          </div>
          </div>
           <div class="date-range-container">
          <label for="start-date">Start Date:</label>
          <input type="date" id="start-date" class="swal2-input" value=${startDate}>
          <br>
          <label for="end-date">End Date:</label>
          <input type="date" id="end-date" class="swal2-input" value=${endDate}>
        </div>
      `,
      showCancelButton: true,
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      footer: `
        <button id="clear-filters" style="
          background-color: #d33;
          color: white;
          border: none;
          border-radius: 5px;
          padding: 10px 24px;
          font-size: 16px;
          cursor: pointer;
          margin-top: 10px;
        ">Clear Filters</button>
      `,
      focusConfirm: false,
      preConfirm: () => {
        const input1 = document.getElementById('swal-input1').value;
        const input2 = document.getElementById('swal-input2').value;
        const input3 = document.getElementById('swal-input3').value;
        const input4 = document.getElementById('swal-input4').value;
        const input5 = document.getElementById('start-date').value;
        const input6 = document.getElementById('end-date').value;

        if (!input1 && !input2 && !input3 && !input4 && !input5 && !input6) {
          Swal.showValidationMessage('At least one field must be filled');
          return null;
        }
        return [input1, input2, input3, input4, input5, input6];
      },
      didRender: () => {
        document.getElementById('clear-filters').addEventListener('click', () => {
          clearFilters();
          Swal.close();
        });
      },
    });

    if (formValues) {
      setRegionName(formValues[0]);
      setPlantName(formValues[1]);
      setProductCategory(formValues[2]);
      setProductName(formValues[3]);
      setStartDate(formValues[4]);
      setEndDate(formValues[5]);
      const values = {
        regionName: formValues[0],
        plantName: formValues[1],
        productCategory: formValues[2],
        productName: formValues[3],
        startDate: formValues[4],
        endDate: formValues[5],
      };
      getReports(values);
    }
  };

  const exportReport = async () => {
    const values = {
      regionName,
      plantName,
      productCategory,
      productName,
      startDate,
      endDate,
    };
    await dispatch(getSummaryReportCsv(values));
  };

  return (
    <div>
      <div className="buttons-container">
        <button
          type="button"
          className="button primary filter-button"
          onClick={handleOnFilter}
        >
          <FontAwesomeIcon icon={faFilter} className="button__icon" />
          Filter
        </button>
        <button type="button" className="button primary filter-button" onClick={exportReport}>
          <FontAwesomeIcon icon={faFileExcel} className="button__icon" />
          Export
        </button>
      </div>
      <div className="temporary-certifcates">
        <div className="table-container">
          <div className="row">
            <div className="col-4">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan={4}>Feed </th>
                  </tr>
                  <tr>
                    <th>Material Wise </th>
                    <th>Order Quantity </th>
                    <th>Fullfilled Quantity </th>
                    <th>Fullfilled % </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.feed?.map((ele) => (
                    <tr key={ele.materialName}>
                      <th>{ele.materialName}</th>
                      <th>{ele.totalRhApprovedQuantity}</th>
                      <th>{Math.round(ele.fullFilledQuantity * 100) / 100}</th>
                      <th>{Math.round(ele.fullFilledPercentage * 100) / 100}</th>
                    </tr>
                  ))}
                  <tr>
                    <th>Total</th>
                    <th>{data.totalFeedQty}</th>
                    <th>{Math.round(data.totalFeedFullFillQty * 100) / 100}</th>
                    <th>{Math.round(data.totalFeedFullFillPer * 100) / 100}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-4">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan={4}>Feed Supplement </th>
                  </tr>
                  <tr>
                    <th>Material Wise </th>
                    <th>Order Quantity </th>
                    <th>Fullfilled Quantity </th>
                    <th>Fullfilled % </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.supplements?.map((ele) => (
                    <tr key={ele.materialName}>
                      <th>{ele.materialName}</th>
                      <th>{ele.totalRhApprovedQuantity}</th>
                      <th>{Math.round(ele.fullFilledQuantity * 100) / 100}</th>
                      <th>{Math.round(ele.fullFilledPercentage * 100) / 100}</th>
                    </tr>
                  ))}
                  <tr>
                    <th>Total</th>
                    <th>{data.totalSuppQty}</th>
                    <th>{Math.round(data.totalSuppFullFillQty * 100) / 100}</th>
                    <th>{Math.round(data.totalSuppFullFillPer * 100) / 100}</th>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-4">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th colSpan={4}>Pharmacy </th>
                  </tr>
                  <tr>
                    <th>Material Wise </th>
                    <th>Order Quantity </th>
                    <th>Fullfilled Quantity </th>
                    <th>Fullfilled % </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.pharmacy?.map((ele) => (
                    <tr key={ele.materialName}>
                      <th>{ele.materialName}</th>
                      <th>{ele.totalRhApprovedQuantity}</th>
                      <th>{Math.round(ele.fullFilledQuantity * 100) / 100}</th>
                      <th>{Math.round(ele.fullFilledPercentage * 100) / 100}</th>
                    </tr>
                  ))}
                  <tr>
                    <th>Total</th>
                    <th>{data.totalPharmacyrQty}</th>
                    <th>{Math.round(data.totalPharmacyFullFillQty * 100) / 100}</th>
                    <th>{Math.round(data.totalPharmacyFullFillPer * 100) / 100}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HnlIndentingSummaryReport;
